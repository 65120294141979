body {
  max-width: 72em;
  margin: 0.8em auto;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.center {
  text-align: center;
}

.content {
  margin: auto auto;
  padding-left: 1em;
  padding-right: 1em;
  border-top: 2px solid rgb(160, 149, 136);
  margin-bottom: 1.5em;
}

header {
  padding: 0 1em;
}

.headertext {
  display: inline-block;
  margin: 0 auto;
  font-family: 'Segoe UI', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  width: 100%;
}

.headertext h1 {
  margin: 0.5em 0;
}

.headertext h4 {
  margin-top: 0.5em;
  margin-bottom: 0;
}

.logo {
  text-align: right;
}

.logo img {
  max-width: 60vw;
  max-height: 3em;
  margin: 0.5em 0;
}

.maincol {
  max-width: 60em;
  margin: 0 auto;
}

/*Radio*/
.button-radio {
  margin-left: 0.8em;
}

.button-radio input[type="radio"] {
  opacity: 0;
  position: fixed;
  width: 0;
}

.button-radio label {
  display: inline-block;
  background-color: #eee;
  padding: 0.6em 1.2em;
  font-size: 90%;
  border: 3px solid #fff;
  border-radius: 10px;
  margin-right: 0.4em;
}

.button-radio label:hover {
  background-color: rgb(90, 200, 250);
}

.button-radio input[type="radio"]:focus + label {
  border: 3px solid #444;
}

.button-radio input[type="radio"]:checked + label {
  background-color: rgb(90, 200, 250);
  border-color: #000;
}

@media screen and (max-width: 47.999em) {
  .headertext h1 {
    font-size: 1.5em;
    text-align: center;
  }
  .headertext h2 {
    font-size: 1.2em;
  }
  .headertext h4{
    text-align: center;
  }
  .logo {
    text-align: center;
  }
}

.privacy {
  margin: 1em
}

.recaptcha {
  display: inline-block;
  margin: 1em auto;
}

.sigWarning {
  color: #333;
  font-size: 1.5em;   
}

.clearButton {
  text-align: right;
  margin-bottom: 1em;
}

.clearButton button {
  color: white;
  border-radius: 4px;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  background: rgb(202, 60, 60);
}

.sigPad {
  width: 702px;
  margin: 0 auto;
}

.signatureCanvas {
  outline: #333 4px solid;
}

.errorText {
  color: rgb(202, 60, 60);
  font-size: 1.5rem;
}

@media screen and (orientation:portrait) {
  .forceLandscape {
    display: none;
  }
}

@media screen and (orientation:landscape) {
  .sigWarning {
    display: none;
  }
  
}

.survey-frame {
  max-width: 64em;
  margin: 0 auto;
}